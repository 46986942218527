import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { Asset, HTTPError, AssetResponse } from "../../../types";

type Options = UseMutationOptions<AssetResponse, HTTPError, Partial<Asset>>;

export const useCreateAsset = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: (asset) => AcceleratorApi.Assets.createAsset(asset),
    ...options
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    createAsset: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
