import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { DataSource, HTTPError, DataSourceResponse } from "../../../types";

type Options = UseMutationOptions<DataSourceResponse, HTTPError, Partial<DataSource>>;

export const useCreateDataSource = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: (dataSource) => AcceleratorApi.DataSource.createDataSource(dataSource),
    ...options
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createDataSource: mutateAsync,
    dataSource: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
