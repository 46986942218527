import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { searchPersonasTemplateByIdQueryKey } from "../query-cache";

export const useSearchAssetPersonaTemplateById = (templateId?: string) => {
  return useQuery({
    queryKey: searchPersonasTemplateByIdQueryKey(templateId),
    queryFn: () => AcceleratorApi.Templates.searchPersonaTemplateById(templateId),
    enabled: Boolean(templateId)
  });
};
