import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetNoteResponse, AssetNote } from "../../../types";

export const useCreateAssetNote = (
  queryOptions: UseMutationOptions<AssetNoteResponse, HTTPError, Partial<AssetNote>> = {}
) => {
  return useMutation({
    mutationFn: AcceleratorApi.AssetNotes.create,
    ...queryOptions,
    onSuccess: queryOptions.onSuccess
  });
};
