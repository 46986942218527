import { UseMutationOptions, useMutation } from "react-query";
import { HTTPError, AssetNoteResponse, AssetNote } from "../../../types";
import { AcceleratorApi } from "../../..";

export const useUpdateAssetNote = (
  queryOptions: UseMutationOptions<AssetNoteResponse, HTTPError, AssetNote>
) => {
  return useMutation({
    mutationFn: (notes) => AcceleratorApi.AssetNotes.update(notes),
    ...queryOptions
  });
};
