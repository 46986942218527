import { UseMutationOptions, useMutation } from "react-query";
import { AlarmThreshold, AlarmThresholdDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousAlarmThreshold: AlarmThreshold;
  alarmThreshold: Partial<AlarmThreshold>;
};

type Options = UseMutationOptions<AlarmThresholdDocumentResponse, HTTPError, Variables>;

export const useUpdateAlarmThreshold = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousAlarmThreshold, alarmThreshold }: Variables) =>
      AcceleratorApi.AlarmThreshold.update(previousAlarmThreshold, alarmThreshold),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    alarmThreshold: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAlarmThreshold: mutateAsync
  };
};
