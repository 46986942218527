import { useMemo, useState } from "react";
import { ApiListResponse, DataGridSearchParams, SearchFilter } from "@packages/service-api";
import { useDebounce } from "@packages/theme-mui-v5";

import { getDataGridSortModel } from "./utils";
import { CACHE_BLOCK_SIZE } from "./AgGrid";
import { IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-community";
import { deepClone } from "@packages/utils";

type DataGridSearch = {
  setSearchTerm: (term: string) => void;
  serverSideDatasource: IServerSideDatasource;
};

type DataGridSearchParamsProps<T> = DataGridSearchParams<T> & {
  searchTerm?: string;
  filter?: SearchFilter<T>;
  skipKeywordList?: string[];
  sortConfig?: { [key: string]: string };
  search: (searchParams: DataGridSearchParams<T>) => Promise<ApiListResponse<T>>;
};

export const useGetServerSideDataSource = <T>({
  search,
  skipKeywordList,
  sortConfig,
  filters,
  size
}: DataGridSearchParamsProps<T>): DataGridSearch => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const debouncedSearchValue = useDebounce(searchTerm, 300);

  const serverSideDatasource: IServerSideDatasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        try {
          params.api.showLoadingOverlay();
          const filter = deepClone(filters) || {};
          Object.keys(params.request.filterModel).map((key) => {
            if (params.request.filterModel[key].values?.length) {
              filter[key] = params.request.filterModel[key].values;
            }
          });

          const nextRows = await search({
            searchTerm: debouncedSearchValue,
            filters: filter,
            from: params.request.startRow,
            size: size || CACHE_BLOCK_SIZE,
            sortModel: getDataGridSortModel(params.request.sortModel, skipKeywordList, sortConfig)
          });

          params.success({ rowData: nextRows?.data, rowCount: nextRows?.total });
          if (!nextRows?.data?.length) {
            params.api.showNoRowsOverlay();
          } else {
            params.api.hideOverlay();
          }
        } catch (error) {
          params.fail();
          params.api.hideOverlay();
        }
      }
    };
  }, [debouncedSearchValue, filters, skipKeywordList, sortConfig]);

  return {
    setSearchTerm,
    serverSideDatasource
  };
};
