import { useMutation, UseMutationOptions } from "react-query";

import { AcceleratorApi, CloneEmptyTemplate } from "../../..";

export function useCloneAssetTemplate<
  Options extends UseMutationOptions<void, unknown, CloneEmptyTemplate>
>(options?: Omit<Options, "mutationFn">) {
  return useMutation({
    ...options,
    mutationFn: ({
      sourceAssetTypeId,
      sourceAssetSubTypeId,
      targetAssetTypeId,
      targetAssetSubTypeId
    }: CloneEmptyTemplate) => {
      return AcceleratorApi.Templates.clone({
        sourceAssetTypeId,
        sourceAssetSubTypeId,
        targetAssetTypeId,
        targetAssetSubTypeId
      });
    }
  });
}
