import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { emptyAssetSubTypesListQueryKey } from "../query-cache";

export const useGetEmptyAssetSubTypesById = (assetTypeId?: string) => {
  return useQuery({
    queryKey: emptyAssetSubTypesListQueryKey(assetTypeId),
    queryFn: () => AcceleratorApi.Templates.getEmptyAssetSubTypes(assetTypeId),
    refetchOnMount: "always",
    enabled: Boolean(assetTypeId)
  });
};
