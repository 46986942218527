import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

type Options = UseMutationOptions<void, HTTPError, string>;

export const useDeleteTagWeights = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: (tagWeightsId: string) => AcceleratorApi.TagWeights.deleteTagWeights(tagWeightsId),
    ...options
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteTagWeights: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
