import { UseMutationOptions, useMutation } from "react-query";
import { AlarmMode, AlarmModeDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousAlarmMode: AlarmMode;
  alarmMode: Partial<AlarmMode>;
};

export const useUpdateAlarmModeById = (
  queryOptions: UseMutationOptions<AlarmModeDocumentResponse, HTTPError, Variables>
) => {
  return useMutation({
    mutationFn: ({ previousAlarmMode, alarmMode }) =>
      AcceleratorApi.AlarmModes.patch(previousAlarmMode, alarmMode),
    ...queryOptions
  });
};
