import { UseMutationOptions, useMutation } from "react-query";
import { TagWeights, HTTPError, TagWeightsResponse } from "../../../types";
import { AcceleratorApi } from "../../..";

type Options = UseMutationOptions<TagWeightsResponse, HTTPError, Partial<TagWeights>>;

export const useCreateTagWeights = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: (tagWeights) => AcceleratorApi.TagWeights.createTagWeights(tagWeights),
    ...options
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createTagWeights: mutateAsync,
    tagWeights: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
