import { UseMutationOptions, useMutation } from "react-query";
import {
  AssetAbnormalThresholds,
  AssetAbnormalThresholdsResponse,
  HTTPError
} from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousAssetAbnormalThresholds: AssetAbnormalThresholds;
  assetAbnormalThresholds: Partial<AssetAbnormalThresholds>;
};

type Options = UseMutationOptions<AssetAbnormalThresholdsResponse, HTTPError, Variables>;

export const useUpdateAssetAbnormalThresholds = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousAssetAbnormalThresholds, assetAbnormalThresholds }: Variables) =>
      AcceleratorApi.Assets.updateAssetAbnormalThresholds(
        previousAssetAbnormalThresholds,
        assetAbnormalThresholds
      ),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    asset: data?.data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateAssetAbnormalThresholds: mutateAsync
  };
};
