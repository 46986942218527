import { useMutation, UseMutationOptions } from "react-query";
import { AcceleratorApi, AlarmMode, AlarmModeDocumentResponse, HTTPError } from "../../..";

export const useCreateAlarmMode = (
  queryOptions: UseMutationOptions<AlarmModeDocumentResponse, HTTPError, Partial<AlarmMode>> = {}
) => {
  return useMutation({
    mutationFn: AcceleratorApi.AlarmModes.create,
    ...queryOptions
  });
};
