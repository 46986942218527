import { UseMutationOptions, useMutation } from "react-query";
import { DataSource, DataSourceResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousDataSource: DataSource;
  dataSource: Partial<DataSource>;
};

type Options = UseMutationOptions<DataSourceResponse, HTTPError, Variables>;

export const useUpdateDataSource = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousDataSource, dataSource }: Variables) =>
      AcceleratorApi.DataSource.patchDataSource(previousDataSource, dataSource),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    dataSource: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateDataSource: mutateAsync
  };
};
