import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { searchTemplateByIdQueryKey } from "../query-cache";

export const useSearchAssetTemplateById = (templateId?: string) => {
  return useQuery({
    queryKey: searchTemplateByIdQueryKey(templateId),
    queryFn: () => AcceleratorApi.Templates.searchById(templateId),
    enabled: Boolean(templateId)
  });
};
