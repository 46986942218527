import { UseMutationOptions, useMutation } from "react-query";
import { AssetProperty, AssetPropertyDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi, useGetAssetPropertyById } from "../../..";

/**
 * Hook to update an AssetProperty
 */
export const useUpdateAssetPropertyById = (
  options: UseMutationOptions<AssetPropertyDocumentResponse, HTTPError, Partial<AssetProperty>>,
  assetPropertyId: string
) => {
  const { assetProperty } = useGetAssetPropertyById(assetPropertyId);
  const queryOptions: UseMutationOptions<
    AssetPropertyDocumentResponse,
    HTTPError,
    Partial<AssetProperty>
  > = {
    mutationFn: (updates) =>
      AcceleratorApi.AssetProperties.patch(assetProperty.id, assetProperty, updates),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetProperty: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAssetProperty: mutateAsync
  };
};
