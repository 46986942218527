import { useCallback, useState } from "react";

import {
  DataGridSearchHookResponse,
  DataGridSearchParams,
  DataGridSearchState
} from "../../types/datagrid-search";

import { ApiListResponse, HTTPError } from "../../types";

export const useDataGridSearch = <T>(callback): DataGridSearchHookResponse<T> => {
  const [searchState, setSearchState] = useState<DataGridSearchState>({
    loading: false,
    error: undefined,
    errorMessage: undefined
  });

  const updateInternalState = (updates: DataGridSearchState) => {
    setSearchState((s) => ({ ...s, ...updates }));
  };

  const search = useCallback(async (params: DataGridSearchParams<T>) => {
    try {
      updateInternalState({ loading: true, error: undefined, errorMessage: undefined });

      const result = await callback({
        searchTerm: params.searchTerm,
        filter: params.filters,
        sortModel: params.sortModel,
        options: {
          from: params.from,
          size: params.size
        }
      });

      updateInternalState({ loading: false, error: undefined, errorMessage: undefined });

      return result as ApiListResponse<T>;
    } catch (err) {
      const error: HTTPError = err;
      let errorString = "";

      try {
        const responseBody = await error.response.json();
        errorString = responseBody?.error;
      } catch {
        const text = await error.response?.text();

        if (text) {
          errorString = `${errorString}: ${text}`;
        }
      }
      updateInternalState({ loading: false, error: err, errorMessage: errorString });
    }
  }, []);

  return { ...searchState, search };
};
