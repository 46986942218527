import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { emptyAssetTypesListQueryKey } from "../query-cache";

export const useGetEmptyAssetTypes = () => {
  return useQuery({
    queryKey: emptyAssetTypesListQueryKey(),
    queryFn: () => AcceleratorApi.Templates.getEmptyAssetTypes(),
    refetchOnMount: "always"
  });
};
