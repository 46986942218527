import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteAssetNameplateTemplate = (
  option: UseMutationOptions<void, HTTPError, string> = {}
) => {
  return useMutation({
    mutationFn: AcceleratorApi.AssetNameplateTemplates.delete,
    ...option
  });
};
