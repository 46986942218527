import { UseMutationOptions, useMutation } from "react-query";
import { TagWeights, TagWeightsResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousTagWeights: TagWeights;
  tagWeights: Partial<TagWeights>;
};

type Options = UseMutationOptions<TagWeightsResponse, HTTPError, Variables>;

export const useUpdateTagWeights = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousTagWeights, tagWeights }: Variables) =>
      AcceleratorApi.TagWeights.patchTagWeights(previousTagWeights, tagWeights),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    tagWeights: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateTagWeights: mutateAsync
  };
};
