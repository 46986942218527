import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AlarmModeDocumentResponse, HTTPError } from "../../../types";

export const useGetAlarmModeById = (alarmModeId: string) => {
  const queryOptions: UseQueryOptions<AlarmModeDocumentResponse, HTTPError> = {
    queryFn: () => AcceleratorApi.AlarmModes.getById(alarmModeId),
    enabled: Boolean(alarmModeId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmMode: data?.data,
    error
  };
};
