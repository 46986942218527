import { UseMutationOptions, useMutation } from "react-query";
import { Asset, AssetResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousAsset: Asset;
  asset: Partial<Asset>;
};

type Options = UseMutationOptions<AssetResponse, HTTPError, Variables>;

export const useUpdateAsset = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousAsset, asset }: Variables) =>
      AcceleratorApi.Assets.updateAsset(previousAsset, asset),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    asset: data?.data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateAsset: mutateAsync
  };
};
