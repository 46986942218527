import { UseMutationOptions, useMutation } from "react-query";
import { Tag, TagResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousTag: Tag;
  tag: Partial<Tag>;
};

type Options = UseMutationOptions<TagResponse, HTTPError, Variables>;

export const useUpdateTag = (options: Options = {}) => {
  const queryOptions: Options = {
    mutationFn: ({ previousTag, tag }: Variables) => AcceleratorApi.Tags.update(previousTag, tag),
    ...options
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    tag: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateTag: mutateAsync
  };
};
