import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteAssetNote = (option: UseMutationOptions<void, HTTPError, string> = {}) => {
  return useMutation({
    mutationFn: AcceleratorApi.AssetNotes.delete,
    ...option
  });
};
