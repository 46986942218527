import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetPropertyNoteResponse, AssetPropertyNote } from "../../../types";

type Options = UseMutationOptions<AssetPropertyNoteResponse, HTTPError, Partial<AssetPropertyNote>>;

export const useCreateAssetPropertyNote = (options: Options) => {
  const queryOptions: Options = {
    mutationFn: (assetPropertyNote) =>
      AcceleratorApi.AssetPropertyNotesApi.create(assetPropertyNote),
    ...options
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createAssetPropertyNote: mutateAsync,
    assetPropertyNote: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
