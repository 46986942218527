import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AlarmThresholdListResponse } from "../../../types";
import { alarmThresholdSearchByIdQueryKey } from "../query-cache";

export const useSearchAlarmThresholdById = (alarmThresholdId: string) => {
  const queryKey = alarmThresholdSearchByIdQueryKey(alarmThresholdId);

  const queryOptions: UseQueryOptions<AlarmThresholdListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AlarmThreshold.searchById(alarmThresholdId),
    enabled: Boolean(alarmThresholdId),
    refetchOnMount: "always"
  };

  const { isLoading, isSuccess, isError, data, error } =
    useQuery<AlarmThresholdListResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmThreshold: data?.data ? data.data[0] : null,
    error
  };
};
