import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { TagWeightsResponse, HTTPError } from "../../../types";
import { tagWeightsByIdQueryKey } from "../query-cache";

export const useGetTagWeightsById = (tagWeightsId: string) => {
  const queryKey = tagWeightsByIdQueryKey(tagWeightsId);

  const queryOptions: UseQueryOptions<TagWeightsResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.TagWeights.getTagWeightsById(tagWeightsId),
    enabled: Boolean(tagWeightsId),
    refetchOnMount: "always"
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery<TagWeightsResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tagWeights: data?.data,
    error
  };
};
