import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

type Options = UseMutationOptions<void, HTTPError, string>;

export const useDeleteAssetPropertyNote = (options: Options) => {
  const queryOptions: Options = {
    mutationFn: (assetNoteId: string) => AcceleratorApi.AssetPropertyNotesApi.delete(assetNoteId),
    ...options
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteAssetPropertyNote: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
